import { Divider } from 'components/navigation/Divider'
import { LeftColumn } from 'components/navigation/LeftColumn'
import { RightColumn } from 'components/navigation/RightColumn'
import { describeNavigation } from 'logic/navigation/describe'
import { SPartnersNavigationCard } from './PartnersNavigationContent.styled'

export const PartnersNavigationContent = () => {
  const { leftColumn, rightColumn } = describeNavigation().navigationCard()

  return (
    <SPartnersNavigationCard>
      <LeftColumn items={leftColumn} />
      <Divider />
      <RightColumn items={rightColumn} />
    </SPartnersNavigationCard>
  )
}
