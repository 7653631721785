import { RightColumnItem } from 'components/navigation/RightColumnItem'
import { Only } from 'components/only/Only'
import { Stack } from 'components/Stack'
import { Caption } from 'components/Typography'
import { RightColumnItem as RightColumnItemProperties } from 'logic/navigation/estatePlanningCard/types'
import { Trans } from 'next-i18next'
import { FC } from 'react'

export const RightColumn: FC<{ items: RightColumnItemProperties[] }> = ({ items }) => (
  <Stack gap="0.75rem">
    <Only on="desktop">
      <Caption>
        <Trans i18nKey={`common.navigation.estate_planning.caption`} />
      </Caption>
    </Only>

    {items.map((item) => (
      <RightColumnItem key={item.key} item={item} />
    ))}
  </Stack>
)
