import { NavigationEstatePlanningCardDescription } from 'logic/navigation/estatePlanningCard/types'
import { colors } from 'utils/colors'

export function describeNavigationPartnersCard(): NavigationEstatePlanningCardDescription {
  return {
    leftColumn: [
      { ...getBookADemoCard(), color: colors.blue[500], navigationKey: 'partners' },
      { ...getSecurityCard(), color: colors.trust.primary, navigationKey: 'partners' },
    ],
    rightColumn: [
      getEmployeeBenefitCard(),
      getInsuranceProviderCard(),
      getFinancialInstitutionsCard(),
      getMemberBenefitsCard(),
    ],
  }
}

function getBookADemoCard() {
  return {
    key: 'book_a_demo',
    type: 'book_a_demo' as const,
    icon: 'Will' as const,
    url: '/business/employee-benefits',
    color: colors.blue[500],
    navigation_key: 'partners' as const,
  }
}

function getSecurityCard() {
  return {
    key: 'security',
    type: 'security' as const,
    icon: 'Handshake' as const,
    url: '/business/employee-benefits',
    color: colors.black,
    navigation_key: 'partners' as const,
  }
}

function getEmployeeBenefitCard() {
  return {
    key: 'employee_benefit',
    type: 'employee_benefit' as const,
    icon: 'Play' as const,
    url: '/business/employee-benefits',
    color: colors.blue[500],
    navigation_key: 'partners' as const,
  }
}

function getInsuranceProviderCard() {
  return {
    key: 'insurance_provider',
    type: 'insurance_provider' as const,
    icon: 'Play' as const,
    url: '/business/insurance_provider',
    color: colors.blue[500],
    navigation_key: 'partners' as const,
  }
}

function getFinancialInstitutionsCard() {
  return {
    key: 'financial_institutions',
    type: 'financial_institutions' as const,
    icon: 'Play' as const,
    url: '/business/financial_institutions',
    color: colors.blue[500],
    navigation_key: 'partners' as const,
  }
}

function getMemberBenefitsCard() {
  return {
    key: 'member_benefits',
    type: 'member_benefits' as const,
    icon: 'Play' as const,
    url: '/business/member_benefits',
    color: colors.blue[500],
    navigation_key: 'partners' as const,
  }
}
